import React, { useEffect } from "react"
import "../../../assets/styles/_index.scss"
import Favicon from "react-favicon"
import GeownOasis from "../../../components/developers/geowndeveloper/ourproperties/GeownOasis";
import TagManager from 'react-gtm-module'

const favicon_logo = require("../../../images/favicon.png");

const GeownOasisPage = () => {
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-J9C48LD' });
  }, []);

  return (
    <>
      <Favicon url={favicon_logo} />

      <GeownOasis />
    </>
  )
}

export default GeownOasisPage
